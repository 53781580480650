import React from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/blog.css"
import { Helmet } from "react-helmet"
import {AiFillRead} from "react-icons/ai"

export default function PostList(props) {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Blog sobre Tecnología, Guías y Tutoriales | Qualoom</title>
        <meta name="title" content="Blog sobre Tecnología, Guías y Tutoriales | Qualoom"/>
        <meta name="description" content="¡Bienvenido al Blog de Qualoom! En este espacio encontrarás varios artículos relacionados con diversas disciplinas del mundo tecnológico."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/blog/"/>
        <link rel="canonical" href="https://www.qualoom.es/blog/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/blog/"/>
        <meta property="og:title" content="Blog sobre Tecnología, Guías y Tutoriales | Qualoom"/>
        <meta property="og:description" content="¡Bienvenido al Blog de Qualoom! En este espacio encontrarás varios artículos relacionados con diversas disciplinas del mundo tecnológico."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/5kTAesmpWfh7APJn42ZzHY/68ebbf65f2c4c21aa8c6909f0014c84d/tw_cards_blog.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>        
        <meta property="fb:admins" content="338114870907726"/>

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/blog/"/>
        <meta property="twitter:title" content="Blog sobre Tecnología, Guías y Tutoriales | Qualoom"/>
        <meta property="twitter:description" content="¡Bienvenido al Blog de Qualoom! En este espacio encontrarás varios artículos relacionados con diversas disciplinas del mundo tecnológico."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/5kTAesmpWfh7APJn42ZzHY/68ebbf65f2c4c21aa8c6909f0014c84d/tw_cards_blog.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>
        
        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/blog/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Blog", "item": {"@id": "https://www.qualoom.es/blog/", "name": "Blog"}}
            ]}`}
        </script>
      </Helmet>
      <div className="background-post" style={{background: `url(../images/blog/cabecera-blog.jpg)`,}}>
        <div className="header_div">
          <Header />
        </div>
        <div className="main">
          <div className="empty"></div>
          <div className="text">
            <h1 class="blog-section-title">Blog</h1>
            <p>¡Bienvenido al Blog de Qualoom! En este espacio encontrarás varios artículos relacionados con diversas 
            disciplinas del mundo tecnológico.</p>
          </div>
        </div>
      </div>
      <div className="blog-main-page">
        <div className="blog-posts">
          {props.data.allContentfulBlogPost.edges.map(edge => (
            <Link to={`/blog/${edge.node.Slug}`} key={edge.node.id}>
              <div className="blog-post">
                <div className="blog-post-image">
                  {" "}
                  {edge.node.featuredImage ? (
                    <img
                      src={
                        edge.node.featuredImage.file.url +
                        "?fm=jpg&fl=progressive"
                      }
                      alt="blog post"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="blog-post-title">
                  <h2>{edge.node.Title}</h2>
                  <div className="info-post">
                    <p><i>{edge.node.publishedDate}</i></p>
                    <div className="post-category">
                      <p>{edge.node.category}</p>
                      <p className="read-time"><AiFillRead/>{edge.node.readTime}</p>
                    </div>
                  </div>
                  <p>{edge.node.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="pagination">
          <div className="first">
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Previous Page
              </Link>
            )}
          </div>
          <div className="first">
            {!isLast && (
              <Link to={nextPage} rel="next">
                Next Page →
              </Link>
            )}
          </div>
          {Array.from({ length: numPages }, (_, i) => (
            <div className="pagination-number" key={i}>
              <Link
                key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? "" : i + 1 + "/"}`}
                activeClassName="active"
              >
                {i + 1}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          Title
          Slug
          id
          description
          featuredImage {
            file {
              url
            }
          }
          publishedDate(formatString: "MMMM Do, YYYY")
          category
          readTime
        }
      }
    }
  }
`
